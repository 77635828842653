import type { SekouData } from "~/types";

type Item = {
  item_cd: string;
  item_name: string;
  // 商品名付加カテゴリ名
  src_name: string;
};

type SekouQuery = {
  attr_num: number[];
  pref: string;
  city: string;
  kw: string[];
  item: Item;
};

const init = {
  attr_num: [],
  pref: "",
  city: "",
  kw: [],
  item: {
    item_cd: "",
    item_name: "",
    src_name: "",
  },
};

export const useSekouQuery = () =>
  useState<SekouQuery>("sekouQuery", () => ({
    ...init,
  }));

export function useSetSekouQuery(data: Ref<SekouData | null>) {
  if (!data.value) return;

  const s = useSekouQuery();

  // dataに変更があった場合の処理
  const watcher = watch(
    () => data.value,
    () => {
      const d = unref(data)!;
      const q = d?.query ?? { ...init };
      s.value = {
        attr_num: q.attr_num,
        pref: q.pref,
        city: q.city,
        kw: q.kw,
        item: {
          item_cd: q?.item?.item_cd ?? "",
          item_name: q?.item?.item_name ?? "",
          src_name: q?.item?.src_name ?? "",
        },
      };
    },
    {
      immediate: true,
      deep: true,
    },
  );
}
